import React from 'react';
import Paper from '@mui/material/Paper';

export function Offer() {
  // const handleScrollToCourses = () => {
  //   const kursyButton = document.getElementById('KursyButton');
  //   if (kursyButton) {
  //     kursyButton.scrollIntoView({ behavior: 'smooth' });
  //   }
  // };

  return (
    <React.Fragment>
      <div className="Offer">
        <Paper className="Offer-paper">
          <h2>O mnie</h2>
          <p>Pomagam twórcom przejść od pomysłu do sprzedaży gotowego produktu online. 
            Tworzę i sprzedaję kursy oraz e-booki, dzięki czemu doskonale rozumiem wyzwania, 
            przed którymi stoją osoby wchodzące na ten rynek. Przetestowałam dziesiątki platform, 
            narzędzi i systemów marketingowych, inwestując tysiące złotych w reklamy i automatyzacje, 
            aby znaleźć najskuteczniejsze metody. Wykorzystuję narzędzia AI, które usprawniły moją pracę i 
            pozwoliły osiągać lepsze wyniki w krótszym czasie. </p>
          <p>Na podstawie tych doświadczeń stworzyłam autorski system, który pomaga moim klientom unikać 
              kosztownych błędów oraz znacznie skrócić czas potrzebny na wprowadzenie produktu na rynek. 
              Dzięki moim strategiom wiele osób już zaczęło zarabiać na swojej wiedzy online. </p>
          <p>Dzielę się swoją wiedzą, aby każdy mógł wykorzystać nowoczesne technologie i pracować efektywniej, 
              niezależnie od etapu, na którym się znajduje.</p>
          <p>Zapraszam do kontaktu i współpracy!</p>
          <p>Anna Stępień</p>
          <p>AStep_Lab</p>
        </Paper>
      </div>
    </React.Fragment>
  );
}
