import React from 'react'; 
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';

const pages = ['Zaloguj się', 'Kontakt'];
const courses = [''];

export function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElCourses, setAnchorElCourses] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleOpenCoursesMenu = (event) => {
    setAnchorElCourses(event.currentTarget);
  };

  const handleCloseCoursesMenu = () => {
    setAnchorElCourses(null);
  };

  const handleRedirectToCourse = () => {
    window.location.href = 'https://asteplab.pl';
  };

  const handleRedirectToLogin = () => {
    window.location.href = 'https://kursy.asteplab.pl';
  };

  const handleScrollToContact = () => {
    const contactSection = document.getElementById('Contact');
    if (contactSection) {
      contactSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleRedirectToHome = () => {
    window.location.href = '/';
  };

  return (
    <AppBar position="static" className="Header" 
    sx={{ 
      backgroundColor: 'var(--bgcolor-header)',
      display: "flex", 
      justifyContent: "center", 
      position: "relative" }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
         
          <Box sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }}>
            <img 
              src={process.env.PUBLIC_URL + '/assets/logo.svg'} 
              alt="Logo" 
              style={{ height: '50px', cursor: 'pointer' }} 
              onClick={handleRedirectToHome}
            />
          </Box>
          <Typography
            variant="h6"
            noWrap
            component="a"
            onClick={handleRedirectToHome}
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'var(--font-logo)',
              fontWeight: 800,
              color: 'var(--color-logo)',
              fontSize: "30px",
              textDecoration: 'none',
              cursor: 'pointer'
            }}
          >
            AStep_Lab
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="var(--color-logo)"
            >
              <MenuIcon sx={{ color: "var(--color-logo)" }} />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{display: { xs: 'block', md: 'none' } }}
            >
              {pages.map((page) => (
                page === 'Kursy' ? (
                  <MenuItem key={page} onClick={handleOpenCoursesMenu}>
                    <Typography textAlign="center">{page}</Typography>
                  </MenuItem>
                ) : (
                  <MenuItem key={page} onClick={page === 'Zaloguj się' ? handleRedirectToLogin : handleScrollToContact}>
                    <Typography textAlign="center">{page}</Typography>
                  </MenuItem>
                )
              ))}
            </Menu>
          </Box>

          <Box sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }}>
            <img 
              src={process.env.PUBLIC_URL + '/assets/logo.svg'} 
              alt="Logo" 
              style={{ height: '50px', cursor: 'pointer' }} 
              onClick={handleRedirectToHome}
            />
          </Box>
          <Typography
            variant="h5"
            noWrap
            component="a"
            onClick={handleRedirectToHome}
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'var(--font-logo)',
              fontSize: "30px",
              fontWeight: 800,
              color: 'var(--color-logo)',
              textDecoration: 'none',
              cursor: 'pointer'
            }}
          >
            AStep_Lab
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: 'flex-end' }}>
            {pages.map((page) => (
              page === 'Kursy' ? (
                <Box key={page}>
                  <Button
                    id="KursyButton"
                    onClick={handleOpenCoursesMenu}
                    sx={{ my: 2, color: 'var(--color-logo)', display: 'block', fontWeight: "700" }}
                  >
                    {page}
                  </Button>
                  <Menu 
                    id="courses-menu"
                    anchorEl={anchorElCourses}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    open={Boolean(anchorElCourses)}
                    onClose={handleCloseCoursesMenu}
                    PaperProps={{
                      sx: {
                        bgcolor: 'rgba(249, 235, 194, 0.7)',
                      },
                    }}
                  >
                    {courses.map((course) => (
                      <MenuItem key={course} onClick={handleRedirectToCourse} 
                      sx={{ 
                        backgroundColor: "var(--button-hover)", 
                        color: 'var(--bgcolor-header)' }}>
                        <Typography textAlign="center" 
                        sx={{ 
                          fontWeight: 'bold',
                          color: 'var(--bgcolor-header)' }}>{course}</Typography>
                      </MenuItem>
                    ))}
                  </Menu>
                </Box>
              ) : (
                <Button
                  key={page}
                  onClick={page === 'Zaloguj się' ? handleRedirectToLogin : handleScrollToContact}
                  sx={{ my: 2, color: 'var(--color-logo)', display: 'block', fontWeight: "700" }}
                >
                  {page}
                </Button>
              )
            ))}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
